import { createRouter, createWebHistory, RouteRecordRaw, RouterView } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

import authenticationRoutes from "./authentication";

// Must be the last one!
const notFoundRoute = {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
};

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        component: () => import("@/layout/Layout.vue"),
        children: [
            {
                path: "/task/:id",
                name: "task",
                props: true,
                component: () => import("@/views/masterdata/tasks/components/Task.vue"),
            },
            {
                path: "/tasks",
                name: "tasks",
                component: () => import("@/views/masterdata/tasks/Tasks.vue"),
            },
            {
                path: "/user/:id",
                name: "user",
                props: true,
                component: () => import("@/views/masterdata/users/components/User.vue"),
            },
            {
                path: "/user",
                name: "usercreate",
                props: true,
                component: () => import("@/views/masterdata/users/components/User.vue"),
            },
            {
                path: "/users",
                name: "users",
                component: () => import("@/views/masterdata/users/Users.vue"),
            },
            {
                path: "/project/:id",
                name: "project",
                props: true,
                component: () => import("@/views/masterdata/projects/components/Project.vue"),
            },
            {
                path: "/projects",
                name: "projects",
                component: () => import("@/views/masterdata/projects/Projects.vue"),
            },
        ],
    },
];

// Add all child routes to parent

// Add global authentication routes with custom layout
const allRoutes: Array<RouteRecordRaw> = routes.concat(authenticationRoutes);
allRoutes.push(notFoundRoute);

const router = createRouter({
    history: createWebHistory(),
    routes: allRoutes,
});

router.beforeEach(() => {
    // reset config to initial state
    store.commit(Mutations.RESET_LAYOUT_CONFIG);

    store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});

export default router;
